<template>
  <div>
    <navBar :title="title"></navBar>
    <ul class="menu">
      <router-link to="/changePassword">
        <li>
          <span>修改密码</span>
          <van-icon color="#ccc" name="arrow" />
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script>
import navBar from '../navBar'

export default {
  name: '',
  components:{
    navBar
  },
  data () {
    return {
      title: {
        name: '账号管理',
        status: true,
      },
    }
  },
  created () {
  },
  computed: {
  },
  methods: {

  }
}
</script>
<style lang="less" scoped>
.menu {
  // border-bottom: 1px solid #ccc;
  li {
    font-size: 14px;
    height: 35px;
    line-height: 35px;
    padding: 0px 25px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

</style>